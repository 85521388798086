<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const data = reactive({
    searchWord: '',
    restaurants: []
  })

  const querySearchAsync = (queryString, cb) => {
    if (data.searchWord.trim() == '') return
    console.log('远程搜索')
    api.product
      .intelligentSearch({
        name: data.searchWord
      })
      .then((res) => {
        console.log(res, '智能搜索')
        const newArr = res.map((item) => {
          // 去掉ec标签, 后期使用
          const cleanedItem = item.replace(/<\/?ec>/g, '') // 使用正则表达式去掉 <ec> 和 </ec> 标签
          return cleanedItem
        })
        const arrObjects = newArr.map((item) => {
          return { key: item, value: item }
        })

        cb(arrObjects)
      })
  }

  // 搜索
  const toSearch = () => {
    // console.log(12321, data.searchWord)
    // if (data.searchWord.trim() == '') {
    //   proxy.$toast(`Please enter a keyword.` , {type: 'warning'})
    //   return
    // }
    let num = JLTools.createCode(4)
    proxy.$router.push({
      name: 'productList',
      query: {
        keyword: data.searchWord,
        id: num
      }
    })
  }
</script>

<template>
  <div class="search-box rowSC">
    <el-autocomplete
      v-model="data.searchWord"
      class="search-input"
      :fetch-suggestions="querySearchAsync"
      placeholder="Search"
      :trigger-on-focus="false"
      @select="toSearch"
      @keydown.enter="toSearch"
    >
      <template #suffix>
        <div class="search-icon-box rowCC">
          <img src="https://obs.pricoo.pk/04528bad.png" class="search-ico" @click="toSearch" />
        </div>
      </template>
    </el-autocomplete>
  </div>
</template>

<style lang="less" scoped>
  .search-box {
    position: relative;
    width: 100%;
    height: 100%;
    .search-icon-box {
      position: relative;
      right: 5px;
      cursor: pointer;
      .search-ico {
        width: 20px;
        height: 20px;
      }
    }
  }
  :deep(.el-autocomplete) {
    width: calc(100%);
    height: 100%;
    .el-input__inner {
      height: 40px;
    }
  }
  :deep(.el-input__wrapper) {
    border-radius: 4px !important;
  }
</style>
